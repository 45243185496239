<template>
  <div
    class="flex flex-grow-0 text-center rounded-full w-auto border border-gray-200 justify-center px-2-5 text-xxs"
    data-cy="lf-pill"
    :style="bgStyle"
    :class="[
      {
        'py-2': size === 'md',
        'py-1': size === 'sm'
      },
      pillClass
    ]"
  >
    <div
      class="flex overflow-hidden place-self-center"
      :style="colorIndex != null ? textStyle : { color: '#868d9c' }"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useAttrs, computed } from "vue";
import { COLORS } from "@/helpers/constants";
import { getPillStyle } from "@/helpers/UI";
import pick from "lodash/pick";

const props = defineProps({
  colorIndex: {
    type: [String, Number],
    default: null
  },
  opaque: {
    type: Boolean,
    default: false
  },
  scorecard: {
    type: Boolean,
    default: false
  },
  size: {
    type: String,
    default: "md"
  }
});

const attrs = useAttrs();

const pillStyles = computed(() =>
  getPillStyle({
    color: COLORS[Number(props.colorIndex)],
    opaque: props.opaque
  })
);

const bgStyle = computed(() => {
  if (props.colorIndex === null) {
    if (props.scorecard) {
      return { backgroundColor: "#F1F4F7" };
    }
    return { backgroundColor: "white" };
  }

  return pick(pillStyles.value, ["backgroundColor", "borderStyle"]);
});

const textStyle = computed(() => {
  return pick(pillStyles.value, ["color"]);
});

const pillClass = computed(() => {
  return (attrs as { class: string })?.class?.includes("font-")
    ? ""
    : "font-medium";
});
</script>
